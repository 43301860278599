const unit = +window
  .getComputedStyle(document.documentElement)
  .getPropertyValue('--unit')
  .replace('px', '');

function smoothScroll(e) {
  e.preventDefault();

  const href = this.getAttribute('href');
  const offsetTop = document.querySelector(href).offsetTop - unit;

  window.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  });
}

export default function homepageHero() {
  const hero = document.querySelector('.hero--homepage');

  if (hero) {
    const heroMedia = hero.querySelectorAll('.hero__media');
    const gridWidth = hero.querySelector('.dot-grid').offsetWidth;
    const extraWidth = gridWidth % unit;

    // Set width of media to equal the nearest visible bg dot on the right
    heroMedia.forEach((media) => {
      media.style.setProperty(
        '--hero-width',
        `${gridWidth - unit - extraWidth}px`
      );
    });

    const anchorLink = hero.querySelector('.hero__button a');

    anchorLink.addEventListener('click', smoothScroll);

    const videoBtn = hero.querySelector('.hero__play-pause');

    if (videoBtn) {
      function playPause() {
        if (video.paused) {
          videoBtn.setAttribute('data-state', 'play');
          video.play();
        } else {
          videoBtn.setAttribute('data-state', 'pause');
          video.pause();
        }
      }

      const video = hero.querySelector('video');

      videoBtn.addEventListener('click', playPause);
    }
  }
}

window.addEventListener('resize', () => {
  setTimeout(() => {
    homepageHero();
  }, 60);
});

document.addEventListener('DOMContentLoaded', function () {
  homepageHero();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', homepageHero);
}
